main.giftVouchers {
    background-image: url('https://www.funfit.im/images/studios/bg2.jpg');
    background-size: cover;
    background-position: center;
    min-height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    > span {
        position: fixed;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(#3D5FCD, 0.5);
        z-index: 0;
    }

    > div {
        margin: 60px 0;
        z-index: 1;
        background: #ffffff;
        padding: 50px 60px 20px 60px;
        border-radius: 10px;
        width: 500px;
        max-width: 100%;

        > .header {
            text-align: center;
            display: grid;
            grid-template-columns: 1fr;
            gap: 15px;

            > img {
                width: 100px;
                margin: 0 auto;
            }

            h1 {
                font-size: 24px;
            }
        }

        > .footer {
            text-align: center;
            padding: 30px 0;
        }

        @media screen and (max-width: 500px){
            padding: 50px 40px 20px 40px;
            border-radius: 0;
            margin: 0;
        }
    }

    @media screen and (max-width: 500px){
        background: #ffffff;

        > span {
            display: none;
        }
    }
}