.booking {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 500px;
    max-width: 90%;
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    gap: 30px;

    .error {
        border: 1px solid #ff4c4c;
        color: #ff4c4c;
        padding: 10px 15px;
        border-radius: 4px;
        text-align: center;

        a {
            color: #ff4c4c;
            text-decoration: underline;
        }
    }

    .success {
        border: 1px solid var(--primary-color);
        color: var(--primary-color);
        padding: 10px 15px;
        border-radius: 4px;
        text-align: center;
    }

    img {
        max-width: 100%;
    }

    .message {
        background: #ffffff;
        width: 100%;
        padding: 15px;
        border-radius: 4px;
    }

    .details {
        p {
            margin-top: 10px;
        }
    }

    .actions {
        display: flex;
        gap: 20px;
    }

    @media screen and (max-width: 600px) {
        .actions {
            flex-direction: column-reverse;
            gap: 20px;
            width: 100%;

        }
    }
}