.sidebar {
    position: fixed;
    width: 300px;
    height: 100vh;
    background: var(--nav-bar-color);
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    padding: 50px 0 0 0;

    > span {
        display: none;
    }

    > a {
        width: 150px;
        font-size: 0;
    }

    .nav {
        width: 100%;

        p {
            color: #ffffff;
            text-align: center;
            padding: 0 35px;
        }

        nav {
            margin-top: 30px;
            display: grid;
            grid-template-columns: 1fr;

            a {
                color: #ffffff;
                padding: 20px 20px 20px 30px;
                transition: 0.2s;

                &:global(.active),
                &:hover {
                    background: var(--nav-bar-active-color);
                }
            }
        }
    }

    @media screen and (max-width: 1000px) {
        position: relative;
        width: 100%;
        height: 100px;
        flex-direction: row;
        padding: 0 20px;
        z-index: 10;

        > a {
            width: 60px;
        }

        .nav {
            display: none;
        }

        .nav {
            width: 100%;
            left: 0;
            z-index: -1;
            position: absolute;
            top: 100px;
            display: block;
            background: var(--nav-bar-color);
            overflow: hidden;
            height: 0;
            transition: 0.5s;
        }

        &.open {
            .nav {
                height: calc(100vh - 100px);
                z-index: 2
            }
        }

        &.native {
            height: 80px;
            position: fixed;
            bottom: 0;
            justify-content: center;
            margin: 0;

            > a {
                display: none;
            }

            :global(#hamburger) {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }

            .nav {
                position: fixed;
                top: 0;
            }

            nav {
                margin-top: 40px;
            }

            &.open {
                .nav {
                    height: calc(100vh - 80px);
                    z-index: 2
                }
            }
        }
    }
}