.entry {
    height: 100vh;
    display: grid;
    grid-template-columns: 1fr 500px;

    * + p {
        margin-top: 15px;
    }

    .fullHeight {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .terms {
            margin-top: 20px;
            border: 1px solid #c3c3c3;
            border-radius: 4px;
            padding: 20px;
            height: calc(100vh - 480px);
            overflow-y: scroll;
        }

        .form {
            margin-top: 20px;
            display: grid;
            grid-template-columns: 1fr;
            gap: 20px;
        }
    }

    .checkbox {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 20px;
        line-height: 170%;
        cursor: pointer;

        input[type="checkbox"] {
            -webkit-appearance: checkbox!important;
            width: 20px;
            height: 20px;
            cursor: pointer;
        }
    }

    .requested {
        padding: 15px;
        border-radius: 4px;
        border: 1px solid #c3c3c3;
        text-align: center;
    }

    .youtube {
        width: 100%;
        aspect-ratio: 16/9;
        margin-top: 20px;
        background: #c3c3c3;
    }

    .actions {
        margin-top: 40px;
        display: grid;
        grid-template-columns: 1fr;
        gap: 20px;
    }

    > div {
        display: flex;
        align-items: center;
        justify-content: center;

        &:nth-of-type(1){
            background: var(--nav-bar-color);

            img {
                width: 300px;
            }
        }

        &:nth-of-type(2){
            background: #ffffff;
            padding: 70px;

            > div {
                width: 100%;

                .error {
                    margin-bottom: 30px;
                    text-align: center;
                    padding: 15px;
                    border-radius: 4px;
                    background: #ff5252;

                    p {
                        color: #ffffff;
                    }
                }

                form {
                    margin-top: 40px;
                    width: 100%;
                    display: grid;
                    grid-template-columns: 1fr;
                    grid-gap: 20px;
                }

                .alternatives {
                    margin-top: 30px;
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                    
                    > * {
                        cursor: pointer;
                    }
                }
            }
        }
    }

    &.native {
        background: var(--primary-color);
        grid-template-rows: 100vh;
        grid-template-columns: 1fr;

        > div {
            &:nth-of-type(1){
                display: none;
            }

            &:nth-of-type(2){
                background: var(--primary-color);

                h1 {
                    color: #ffffff;
                }

                .alternatives > * {
                    color: #ffffff;
                }

                :global(.btnPrimary){
                    background: rgba(#000000, 0.15)
                }

                input {
                    border: 1px solid #ffffff!important;
                }
            }
        }
    }

    @media screen and (max-width: 950px) {
        grid-template-columns: 1fr;
        grid-template-rows: 250px auto;

        > div {
            &:nth-of-type(1) {
                img {
                    width: 150px;
                }
            }

            &:nth-of-type(2){

            }
        }
    }
}