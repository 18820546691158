@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap');
@import 'hamburger';

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --primary-color: #000000;
    --danger-color: #ff4c4c;
    --nav-bar-color: #000000;
    --nav-bar-active-color: #000000;
}

* {
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    font-style: normal;
}

html,
body {
    padding: 0;
    margin: 0;
    background: #F7F5F5;
}

a {
    color: inherit;
    text-decoration: none;
}

* {
    box-sizing: border-box;
    font-size: 16px;
}

main > section {
    width: 700px;
    max-width: 100%;
    padding: 40px;
    background: #ffffff;
    border-radius: 10px;
    margin-top: 40px;

    @media screen and (max-width: 600px) {
        padding: 30px;
        margin-top: 25px;
    }
}

main > .linkBlock {
    width: 700px;
    max-width: 100%;
    margin-top: 40px;
}

// Typography

h1,
h2,
h3,
h4,
p,
a,
button,
input,
textarea,
select {
    color: #000000;
    margin: 0;
}

h1,
h2,
h3 {
    margin: 0;
    line-height: 150%;
    font-weight: 600;
}

h1 {
    color: var(--primary-color);
    font-size: 22px;
}

h2 {
    font-size: 20px;
}

h3 {
    font-size: 18px;
}

p,
a,
ul,
li,
button,
input,
textarea,
select {
    font-size: 16px;
    line-height: 170%;
}

p,
ul,
li,
a {
    color: #000000;
}

strong {
    font-weight: 600;
}

* + h2,
* + h3 {
    margin-top: 30px;
}

a {
    text-decoration: none;
    cursor: pointer;
}

p a {
    color: blue;
}

input {
    -webkit-appearance: none;
    font-size: 16px;
    padding: 0 0 18px 0;
    border: 0;
    border-bottom: 1px solid #c3c3c3;
    outline: none;
    
    &:disabled {
        cursor: not-allowed;
    }
}

button,
input[type=submit],
textarea,
select {
    -webkit-appearance: none;
    font-size: 16px;
    padding: 12px 20px;
    border-radius: 4px;
    border: 1px solid #c3c3c3;
    outline: none;

}

ul {
    padding-left: 25px;

    li:nth-of-type(n+2){
        margin-top: 5px;
    }
}


// Buttons

button {
    cursor: pointer;
}

.btn {
    display: inline-block;
    font-size: 16px;
    padding: 12px 20px;
    border-radius: 4px;
    transition: 0.2s;
    text-align: center;
    cursor: pointer;
    border: 0!important;
    min-height: 52px;
    min-width: 125px;

    &:hover {
        opacity: 0.8;
    }

    &:disabled {
        opacity: 0.5;
        cursor: default;
    }
}

.btnPrimary {
    background: var(--primary-color);
    border: 1px solid var(--primary-color);
    color: #ffffff;
}

.btnDanger {
    background: var(--danger-color);
    border: 1px solid var(--danger-color);
    color: #ffffff;
}

.btnEmpty {
    border: 1px solid #dbdbdb!important;
}

.btnLeft {
    text-align: left;
}

button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}