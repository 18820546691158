.choosePlan {
    background: #ffffff;
    border-radius: 12px;
    padding: 40px;
    text-align: center;
    max-width: 850px;
    margin: 40px auto 0 auto;

    .plans {
        margin-top: 30px;
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 20px;

        .plan {
            width: 100%;
            border: 1px solid #CBCBCB;
            border-radius: 6px;
            padding: 15px;
            text-align: left;
            display: flex;
            align-items: center;
            justify-content: space-between;

            p {
                margin-top: 10px;
            }

            .price {
                margin-top: 3px;
                display: flex;
                gap: 5px;

                p {
                    font-size: 14px;
                    margin-top: 3px;
                }
            }

            @media screen and (max-width: 680px) {
                align-items: flex-start;
                flex-direction: column;
                gap: 20px;
            }
        }
    }
}

.choosePlanMessage {
    max-width: 500px;
    margin: 30px auto 0 auto;
}