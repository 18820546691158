.header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 40px 60px;
    background: #ffffff;

    &.native {
        background: #3d5fcd;

        h1 {
            color: #ffffff;
        }
    }

    @media screen and (max-width: 1000px) {
        align-items: flex-start;
        flex-direction: column;
        gap: 20px;
        padding: 40px;

        :global(.btn) {
            width: 100%;
        }

        &.native {
            padding: 60px 40px 10px 40px;
        }
    }
}