.bookings {
    margin-top: 40px;

    .none {
        background: #ffffff;
        padding: 20px;
        text-align: center;
        border-radius: 4px;
    }

    .booking {
        background: #ffffff;
        border-radius: 4px;
        padding: 40px;
        display: flex;
        justify-content: space-between;
        position: relative;
        align-items: flex-start;
        flex-direction: column;
        gap: 30px;

        .type {
            text-transform: uppercase;
            letter-spacing: 1px;
            font-size: 12px;
            background: #efefef;
            padding: 5px 10px;
            border-radius: 4px;
        }

        .details {
            display: flex;
            align-items: center;
            gap: 50px;
            cursor: pointer;

            > div {
                p {
                    margin: 0;
                    text-transform: capitalize;
                }

                p:first-of-type {
                    text-transform: uppercase;
                    font-size: 12px;
                    color: #999999;
                    letter-spacing: 1px;
                }
            }
        }

        .actions {
            display: grid;
            grid-template-columns: 100%;
            gap: 20px;
            width: 100%;

            > div {
                width: 100%;

                &.invite {
                    max-width: 100%;
                    padding: 20px 20px;
                    border-radius: 4px;
                    border: 1px solid #dbdbdb;

                    div {
                        margin-top: 10px;
                    }

                    .link {
                        display: flex;
                        align-items: center;
                        gap: 20px;

                        > p {
                            padding: 10px 15px;
                            border-radius: 4px;
                            border: 1px solid #dbdbdb;
                            line-break: anywhere;
                            background: #f1f1f1;
                            width: 100%;
                        }

                        > button {
                            flex-shrink: 0;
                        }

                        @media screen and (max-width: 600px) {
                            flex-direction: column;
                        }
                    }
                }

                p {
                    display: flex;
                    align-items: center;
                    gap: 10px;

                    input {
                        width: 100%;
                    }
                }
            }
        }

        &:nth-of-type(n+2){
            margin-top: 20px;
        }

        &.cancelled {
            opacity: 0.4;
            cursor: not-allowed;

            .details {
                cursor: not-allowed;
            }
        }

        @media screen and (max-width: 1500px){
            .details {
                flex-wrap: wrap;
                gap: 30px
            }

            .actions {
                flex-wrap: wrap;
                gap: 15px;
            }
        }
    }
}