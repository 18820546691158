.networkError {
    background: #3d5fcd;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 60px;
    gap: 30px;
    text-align: center;

    > img {
        width: 60px;
    }

    > h1,
    > p {
        color: #ffffff;
    }

    > p {
        margin: 0;
    }

    > button {
        position: absolute;
        width: calc(100% - 120px);
        bottom: 40px;
    }
}