.tabs {
    width: 100%;
    height: 90px;
    border-top: 1px solid #dfdfdf;
    position: fixed;
    bottom: 0;
    background: #ffffff;
    display: flex;
    justify-content: center;
    gap: 30px;

    > a {
        margin-top: 15px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 5px;

        p {
            font-size: 13px;
        }

        img {
           width: 20px;
        }

        &:global(.active) {
            p {
                color: #3D5FCD;
            }
        }
    }
}