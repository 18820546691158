.balance {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    flex-wrap: wrap;

    p {
        margin: 0!important;
    }
}

.products {
    margin-top: 40px;

    .none {
        background: #ffffff;
        padding: 20px;
        text-align: center;
        border-radius: 4px;
    }

    .product {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px 20px 20px 30px;
        border-radius: 4px;
        border: 1px solid #c3c3c3;

        p {
            margin: 0;
        }

        &:nth-of-type(n+2) {
            margin-top: 20px;
        }

        @media screen and (max-width: 600px) {
            flex-direction: column;
            align-items: flex-start;
            padding: 20px;
            gap: 15px;

            > a {
                width: 100%;
            }
        }
    }
}

.discountCode {
    background: #ffffff;
    border-radius: 6px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    min-height: 83.2px;

    > input {
        width: 100%;
    }

    > img {
        width: 20px;
        cursor: pointer;
    }

    @media screen and (max-width: 500px){
        grid-template-columns: 1fr;
    }
}

.checkout {
    width: 600px;
    max-width: 100%;

    > .summary {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 40px;
        margin-bottom: 20px;
        text-align: center;
        padding: 30px;
        background: #ffffff;
        border-radius: 6px;
        border: 1px solid #c3c3c3;

        p {
            margin: 0!important;
        }
    }

    > a {
        width: 100%;
    }

    .success {
        border: 1px solid var(--primary-color);
        color: var(--primary-color);
        border-radius: 4px;
        padding: 10px 15px;
        text-align: center;
        margin-bottom: 20px;
    }
}

.redeem {
    .message {
        margin-top: 10px;
        padding: 10px 15px;
        border-radius: 4px;
        border: 1px solid #c3c3c3;
        text-align: center;
    }

    > div {
        margin-top: 20px;
        display: grid;
        grid-template-columns: 1fr auto;
        gap: 20px;
        width: 100%;
    }

    @media screen and (max-width: 500px) {
        > div {
            grid-template-columns: 1fr;
        }
    }
}