.status {
    display: flex;
    align-items: center;
    gap: 20px;

    :global(.btn) {
        width: 150px;
    }
}

.plan {
    margin: 25px 0;
    border: 1px solid #CBCBCB;
    border-radius: 8px;
    padding: 20px;
    text-align: left;

    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .price {
            display: flex;
            gap: 5px;

            p {
                font-size: 14px;
                margin-top: 5px;
            }
        }
    }
}

.paymentMethod {
    margin: 25px 0;
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;

    > div {
        display: grid;
        grid-template-columns: 120px 1fr;
        gap: 20px;
        align-items: center;

        p {
            margin: 0!important;

            &:nth-of-type(2) {
                padding: 10px 15px;
                background: #F0F0F0;
            }
        }
    }
}