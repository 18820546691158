.loading {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    svg {
        width: 60px;
    }

    &.button {
        svg {
            width: 28px;
        }
    }
}