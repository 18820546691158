.calendar {
    height: 100vh;
    display: flex;
    flex-direction: column;

    .controls {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 40px;

        .studioSelector {
            width: 200px;
            background-image: url('/images/drop-down.svg');
            background-repeat: no-repeat;
            background-size: 17px;
            background-position: 95%;
        }

        .weekSelector {
            display: flex;
            gap: 20px;
        }

        @media screen and (max-width: 550px) {
            flex-direction: column;
            gap: 15px;

            .studioSelector {
                width: 100%;
            }

            .weekSelector {
                width: 100%;
                justify-content: space-between;
            }
        }
    }

    .sessions {
        background: #ffffff;
        flex-grow: 1;
        display: flex;

        .day {
            width: 100%;
            display: flex;
            flex-direction: column;
            text-align: center;

            .heading {
                padding: 20px;
                border-top: 1px solid #DFDFDF;
                border-bottom: 1px solid #DFDFDF;
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                    width: 12px;
                    margin-right: 15px;
                    transform: rotate(-90deg);
                    transition: 0.2s;
                    display: none;
                }
            }

            .times {
                flex-grow: 1;
                display: flex;
                flex-direction: column;

                .session {
                    padding: 10px 0;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    
                    &.unavailable {
                        background: #efefef;
                        cursor: not-allowed;
                        color: #999999;
                    }
                }

                * + .session {
                    border-top: 1px solid #DFDFDF;
                }
            }

            &:nth-of-type(n+2) {
                border-left: 1px solid #DFDFDF;
            }
        }

        @media screen and (max-width: 950px) {
            flex-direction: column;
            
            .day {
                text-align: left;

                &:nth-of-type(n+2) {
                    border-left: 0;
                }

                .heading {
                    position: sticky;
                    top: 0;
                    background: #ffffff;
                    padding: 20px 40px;
                    border-bottom: 0;
                    justify-content: flex-start;
                    
                    img {
                        display: block;
                    }
                }

                .times {
                    display: none;

                    .session {
                        justify-content: flex-start;
                        padding: 10px 40px;
                    }
                }

                &.open {
                    .heading {
                        border-bottom: 1px solid #DFDFDF;

                        img {
                            transform: rotate(0);
                        }
                    }

                    .times {
                        display: flex;
                    }
                }
            }
        }
    }
}