.dashboard {
    height: 100vh;

    .outlet {
        position: absolute;
        width: calc(100% - 300px);
        left: 300px;
        height: 100vh;

        main {
            padding:  0 60px 60px 60px;

            * + p {
                margin-top: 15px;
            }
        }
    }

    &.native {
        .outlet {
            position: absolute;
            width: 100%;
            left: 0;
            height: 100vh;

            main {
                padding: 0 40px 120px 40px;
            }
        }
    }

    @media screen and (max-width: 1000px) {
        .outlet {
            position: relative;
            width: 100%;
            left: 0;
            height: calc(100vh - 100px);

            main {
                padding: 0 40px 40px 40px;
            }
        }
    }

    @media screen and (max-width: 600px) {
        .outlet {
            main {
                padding: 0 20px 40px 20px;
            }
        }

        &.native {
            .outlet {
                main {
                    padding: 0 20px 120px 20px;
                }
            }
        }
    }
}