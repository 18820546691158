.personalAccessCode {
    margin-top: 20px;

    input {
        width: 100%;
    }
}

.existing {
    display: flex;
    gap: 20px;
    align-items: center;

    p {
        width: 100%;
        padding: 13px 15px;
        background: #F0F0F0;
    }

    @media screen and (max-width: 680px) {
        align-items: flex-start;
        flex-direction: column;
    }
}

.error {
    border: 1px solid red;
    padding: 10px 15px;
    border-radius: 4px;
    color: red;
}

.pinFieldContainer {
    display: flex;

    input {
        border-radius: 0!important;
        text-align: center;

        &:first-child {
            border-top-left-radius: 5px !important;
            border-bottom-left-radius: 5px !important;
        }

        &:last-child {
            border-top-right-radius: 5px !important;
            border-bottom-right-radius: 5px !important;
        }

        &:nth-of-type(n+2){
            border-left: 0!important;
        }
    }
}

.actions {
    margin-top: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;

    @media screen and (max-width: 680px) {
        grid-template-columns: 1fr;
        order: -1;
    }
}