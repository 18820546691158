.minors,
.form {
    width: 100%;

    input[type="date"] {
        min-height: 55px;
        background: #ffffff;
    }

    h2 {
        margin-bottom: 20px;
    }

    > div {

        button,
        input,
        select,
        textarea {
            margin-top: 5px;
            width: 100%;
        }

        &:nth-of-type(n+2) {
            margin-top: 20px;
        }

        &.document {
            margin-top: 40px;
            padding: 25px 25px;
            border-radius: 4px;
            border: 1px solid #dbdbdb;
            background: #ffffff;
        }
    }

    .minor {
        padding: 15px 20px;
        border-radius: 4px;
        border: 1px solid #dbdbdb;
        display: flex;
        justify-content: space-between;

        p {
            margin: 0!important;
            display: flex;
            align-items: center;

            img {
                width: 20px;
                margin-right: 10px;
            }
        }

        @media screen and (max-width: 500px){
            flex-direction: column;
        }
    }

    a {
        margin-top: 20px;
        width: 100%;
    }
}

.minors > p {
    margin-bottom: 20px;
}

.checkbox {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 15px;
    line-height: 170%;
    cursor: pointer;

    input[type="checkbox"] {
        -webkit-appearance: checkbox!important;
        width: 15px;
        height: 15px;
        cursor: pointer;
    }
}

.error {
    border: 1px solid #ff4c4c;
    color: #ff4c4c;
    padding: 10px 15px;
    border-radius: 4px;
    text-align: center;
}

.marketing {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 40px;

    :global(.btn) {
        flex-shrink: 0;
    }

    @media screen and (max-width: 600px){
        flex-direction: column;

        :global(.btn) {
            width: 100%;
        }
    }
}

.logout {
    display: block;
    margin-top: 20px;
}