.heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

input,
select {
    -webkit-appearance: none!important;
    font-size: 16px!important;
    padding: 12px 16px!important;
    border-radius: 4px!important;
    border: 1px solid #e7e7e7!important;
    outline: none;
}

.select {
    input {
        height: 40px;
        border: 0!important;
        padding: 0!important;
    }
}

.list {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
    margin-top: 40px;
}

.form {
    background: #ffffff;
    padding: 40px;
    border-radius: 6px;
    margin-top: 40px;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;

    > div {
        display: grid;
        grid-template-columns: 130px auto;
        align-items: center;

        > * {
            width: 100%;
        }
    }

    @media screen and (max-width: 1000px) {
        padding: 30px;

        > div {
            grid-template-columns: 1fr;
            gap: 10px;
        }
    }
}

.withheld {
    grid-template-columns: 1fr;
    margin-top: 20px;

    .group {
        border: 1px solid #dbdbdb;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px;
        border-radius: 4px;

        p {
            margin: 0!important;
        }
    }
}

.period {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 20px!important;
    gap: 20px;

    > img {
        cursor: pointer;
    }
}

.transaction,
.product,
.studio,
.user,
.discountCode,
.membership {
    background: #ffffff;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 26px 30px;
    gap: 15px;
    border: 1px solid #ffffff;
    transition: 0.2s;

    p {
        margin: 0!important;
    }

    &:hover {
        border: 1px solid #ebebeb;
    }

    &.disabled {
        opacity: 0.4;
    }

    .membershipInfo {
        text-transform: capitalize;
        background: #e1e1e1;
        padding: 2px 8px;
        border-radius: 4px;
        font-size: 14px;

        &.active {
            background: var(--primary-color);
        }

        &.pastDue {
            background: var(--danger-color);
        }
    }

    @media screen and (max-width: 1000px){
        max-width: 100%;
        width: 100%;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        a {
            max-width: 100%;
            width: 100%;
            flex-grow: 0;
            box-sizing: border-box;
        }

        p {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}

.withFilters {
    .filters {
        width: 100%;
        background: #ffffff;
        padding: 30px;
        border-radius: 6px;
        margin-top: 40px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: 20px;

        > div {
            width: 100%;

            p {
                opacity: .5;
                margin-bottom: 10px;
            }

            input,
            select {
                width: 100%;
                padding: 12px 20px;
                border: 1px solid #c3c3c3;
                border-radius: 4px;
            }
        }

        @media screen and (max-width: 800px){
            grid-template-columns: 1fr 1fr;
        }

        @media screen and (max-width: 600px){
            grid-template-columns: 1fr;
        }
    }
}

.actions {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 20px;
    margin-top: 30px;
}

.cancelled {
    margin-top: 40px;
    width: 100%;
    background: var(--danger-color);
    padding: 15px;
    border-radius: 4px;
    text-align: center;
    display: block;
    margin-bottom: 40px;
    color: #ffffff;
}

.shared {
    margin-top: 40px;
    width: 100%;
    background: #999999;
    padding: 15px;
    border-radius: 4px;
    text-align: center;
    display: block;
    margin-bottom: 40px;
    color: #ffffff;
}

.booking {
    background: #ffffff;
    padding: 30px;
    border-radius: 4px;
    margin-top: 40px;

    .details {
        display: grid;
        grid-template-columns: 1fr;
        gap: 20px;

        > div {
            width: 100%;

            p {
                opacity: 0.5;
                margin-bottom: 10px;
            }

            input {
                width: 100%;
            }
        }
    }

    .actions {
        margin-top: 30px
    }
}

.payments {
    background: #ffffff;
    padding: 40px;
    border-radius: 6px;
    margin-top: 40px;
    width: 100%;

    > div {
        margin-top: 20px;
        display: grid;
        grid-template-columns: 1fr;
        gap: 10px;

        > div {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            gap: 20px;

            p {
                margin: 0!important;

                &.status {
                    text-transform: capitalize;
                }

                &.amount {
                    text-align: right;
                }
            }
        }
    }
}