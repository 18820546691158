#hamburger {
    cursor: pointer;
    display: none;
    height: 30px;
    width: 30px;
    position: absolute;
    top: 38px;
    right: 20px;

    span {
        display: block;
        position: absolute;
        top: 50%;
        height: 2px;
        width: 100%;
        transform: translateY(-50%);
        -o-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        background: #ffffff;
        transition: transform 0.1s ease-in;

        &::before,
        &::after {
            content: "";
            display: block;
            position: absolute;
            height: 2px;
            width: 100%;
            background: #ffffff;
        }

        &::before {
            bottom: 10px;
            transition: transform 0.1s ease-in, bottom 0.1s ease-out 0.2s;
        }

        &::after {
            top: 10px;
            transition: background 0.01s ease 0.1s, top 0.1s ease-out 0.2s;
        }
    }

    &.close span {
        transform: rotate(45deg) translate(-1px, -1px);
        -o-transform: rotate(45deg) translate(-1px, -1px);
        -ms-transform: rotate(45deg) translate(-1px, -1px);
        -moz-transform: rotate(45deg) translate(-1px, -1px);
        -webkit-transform: rotate(45deg) translate(-1px, -1px);
        transition: transform 0.1s ease-out 0.2s;

        &:after {
            top: 0;
            background: none;
            transition: top 0.1s ease-in, background 0.01s ease-out 0.1s;
        }

        &::before {
            bottom: 0;
            transform: rotate(-90deg);
            -o-transform: rotate(-90deg);
            -ms-transform: rotate(-90deg);
            -moz-transform: rotate(-90deg);
            -webkit-transform: rotate(-90deg);
            transition: bottom 0.1s ease-in, transform 0.1s ease-out 0.2s;
        }
    }

    @media screen and (max-width: 1000px){
        display: block;
    }
}